'use client';
import { useCallback, useMemo, useState } from 'react';
import { Key } from 'react-aria-components';
import { IMenuItem, IMenuItemsGroup, MenuKey } from '../Menu';
import { UseMenuSearch, useMenuSearch } from '../Menu/MenuSearch/useMenuSearch';

export const useDropdownMenu = (props: {
  value?: MenuKey | MenuKey[];
  selectedKeys?: MenuKey[] | Set<MenuKey>;
  disabledKeys?: MenuKey[] | Set<MenuKey>;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  open?: boolean;
  options: IMenuItem[];
  groups?: IMenuItemsGroup[];
  onChange?: (value: MenuKey | MenuKey[]) => void;
  onOpenChange?: (isOpen: boolean) => void;
  sort?: UseMenuSearch<IMenuItem>['sort'];
  withMultiple?: boolean;
}) => {
  const {
    value: initialValue,
    selectedKeys: initialSelectedKeys = initialValue,
    options,
    hiddenKeys: initialHiddenKeys,
    disabledKeys: initialDisabledKeys,
    groups: initialGroups,
    open: initialOpen = false,
    onChange,
    onOpenChange,
    withMultiple = true
  } = props;

  const [open, setOpen] = useState(initialOpen);

  const {
    filter,
    filteredKeys: hiddenKeys,
    onFilterChange,
    sort
  } = useMenuSearch<IMenuItem>(options);

  const finalHiddenKeys = useMemo(
    () => [...(initialHiddenKeys || []), ...(hiddenKeys || [])],
    [initialHiddenKeys, hiddenKeys]
  );

  const groups = useMemo<IMenuItemsGroup[]>(() => {
    const grouped =
      initialGroups ||
      options.reduce(
        (groups, { group, ...item }) => ({
          ...groups,
          [group || '']: { items: [...(groups[group || '']?.items || []), item] }
        }),
        {} as Record<string, { items: IMenuItem[] }>
      );

    return Object.keys(grouped).map((header) => ({
      header,
      ...grouped[header],
      collapsible: options.length > 8
    }));
  }, [options, initialGroups]);

  const disabledKeys = useMemo<MenuKey[]>(
    () => initialDisabledKeys || options.filter((it) => it.disabled).map((it) => it.id as MenuKey),
    [options, initialDisabledKeys]
  );

  const selectedKeys = useMemo<MenuKey[]>(
    () =>
      (
        (Array.isArray(initialSelectedKeys) ? initialSelectedKeys : [initialSelectedKeys]).filter(
          Boolean
        ) as MenuKey[]
      ).slice(0, withMultiple ? undefined : 1),
    [initialSelectedKeys, withMultiple]
  );

  const selectedProperties = useMemo(
    () => options?.filter((it) => it.id && selectedKeys.includes(it.id)),
    [options, selectedKeys]
  );
  const [multiple, setMultiple] = useState<boolean>(withMultiple && selectedProperties?.length > 1);

  const value = selectedKeys;
  const setValue = useCallback(
    (selection) => {
      onChange?.(multiple ? Array.from(selection || []) : selection, value);
    },
    [value, onChange, multiple]
  );

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      onFilterChange('');
      setOpen(isOpen);
      onOpenChange?.(isOpen);
      if (isOpen) {
        setMultiple?.(withMultiple && selectedProperties?.length > 1);
      }
    },
    [onFilterChange, selectedProperties?.length, withMultiple]
  );

  return {
    groups,
    value,
    setValue,
    open,
    setOpen,
    multiple,
    setMultiple,
    sort,
    filter,
    onFilterChange,
    hiddenKeys: finalHiddenKeys,
    disabledKeys,
    selectedKeys,
    selectedProperties,
    onOpenChange: handleOpenChange
  };
};
