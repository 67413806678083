import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { classNames } from '../../../utils/classNames';

export interface MenuSearchEmptyResultProps {
  className?: string;
  label?: string;
}

export const MenuSearchEmptyResult: React.FC<MenuSearchEmptyResultProps> = React.memo((props) => (
  <div
    className={classNames(
      'flex gap-x-2 px-4 py-4 items-center text-xs text-gray-500',
      props.className
    )}
  >
    <ExclamationCircleIcon className={'w-4 h-4 text-gray-500'} />
    <span>{props.label ?? 'No results found'}</span>
  </div>
));
