import { XMarkIcon } from '@heroicons/react/20/solid';
import { ArrowsPointingOutIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { ButtonProps, classNames } from '#/packages/ui';

export type ButtonAction = ButtonProps & {
  active?: boolean;
  showOnHover?: boolean;
};

export type UseActionsProps = {
  actions?: ButtonAction[];
  withToggle?: boolean;
  toggleIconProps?: ButtonProps['iconProps'];
  toggleIconVisible?: boolean;

  id: string;
  label: string;
  onEdit?: () => void;
  onReset?: () => void;
  editProps?: Partial<ButtonProps>;
  resetProps?: Partial<ButtonProps>;
};

export const useActions = (props: UseActionsProps) => {
  const {
    withToggle,
    toggleIconVisible,
    toggleIconProps,
    actions,

    id = '',
    label = '',
    onEdit,
    onReset,
    editProps,
    resetProps
  } = props;

  const finalActions = useMemo<ButtonAction[]>(
    () =>
      [
        onEdit
          ? {
              id: 'edit',
              showOnHover: true,
              tip: ['Edit', label].filter(Boolean).join(' '),
              'data-testid': ['edit', id].filter(Boolean).join('-'),
              icon: ArrowsPointingOutIcon,
              ...editProps,
              iconProps: { className: 'w-3 h-3 m-0.5', strokeWidth: 2 },
              onClick: () => onEdit()
            }
          : null,
        onReset
          ? {
              id: 'reset',
              tip: ['Remove', label].filter(Boolean).join(' '),
              'data-testid': ['remove', id].filter(Boolean).join('-'),
              icon: XMarkIcon,
              ...resetProps,
              onClick: () => onReset()
            }
          : null,
        ...(actions || [])
      ].filter(Boolean) as ButtonAction[],
    [actions, id, label, editProps, resetProps, onEdit, onReset]
  );

  const visibleActions = finalActions.filter(
    (action) => !action.showOnHover || action.active
  ) as ButtonAction[];
  const visibleCount = visibleActions.length;
  const hiddenCount = finalActions.length - visibleActions.length;

  const contentClassName = withToggle
    ? classNames(
        '[&>div:first-child>span.truncate]:sm:w-[calc(100%)] [&>div:first-child>span.truncate]:transition-all',
        [
          '[&>div:first-child>span]:sm:w-[calc(100%-20px)]',
          '[&>div:first-child>span]:sm:w-[calc(100%-40px)]',
          '[&>div:first-child>span]:sm:w-[calc(100%-60px)]'
        ][visibleCount - 2],
        [
          '[&>div:first-child>span]:sm:group-hover/dropdown:w-[calc(100%-20px)]',
          '[&>div:first-child>span]:sm:group-hover/dropdown:w-[calc(100%-40px)]',
          '[&>div:first-child>span]:sm:group-hover/dropdown:w-[calc(100%-60px)]'
        ][hiddenCount - 1]
      )
    : classNames(
        ['', 'mr-6', 'mr-11'][finalActions?.length || 0],
        ['', 'sm:mr-5', 'sm:mr-10'][hiddenCount]
      );

  const finalToggleIconProps = useMemo(() => {
    return {
      ...toggleIconProps,
      className: classNames(
        toggleIconProps?.className,
        '-mr-1',
        ['ml-2', 'ml-2', 'ml-7', 'ml-12'][finalActions?.length || 0],
        ['sm:ml-2', 'sm:ml-2', 'sm:ml-7', 'sm:ml-12'][hiddenCount],
        finalActions?.length
          ? toggleIconVisible
            ? 'opacity-100 group-hover/breakdown-item:opacity-0'
            : 'opacity-0'
          : ''
      )
    };
  }, [toggleIconProps, finalActions, toggleIconVisible]);

  return {
    actions: finalActions,
    contentClassName,
    toggleIconProps: finalToggleIconProps
  };
};
