'use client';

import { getWeeksInMonth } from '@internationalized/date';
import React from 'react';
import { AriaDatePickerProps, DateValue, useCalendarGrid, useLocale } from 'react-aria';
import { RangeCalendarState } from 'react-stately';
import { CalendarCell } from './CalendarCell';

export const CalendarGrid: React.FC<{
  state: RangeCalendarState;
  disableRange?: boolean;
  compareStart?: AriaDatePickerProps<DateValue>;
  compareEnd?: AriaDatePickerProps<DateValue>;
}> = ({ state, disableRange, ...props }) => {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid({}, state);
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding='0' className='flex-1'>
      <thead {...headerProps} className='text-gray-400 text-xs font-medium'>
        <tr>
          {weekDays.map((day, index) => (
            <th className='text-center h-9 w-9 font-normal' key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    disableRange={disableRange}
                    compareStart={props.compareStart}
                    compareEnd={props.compareEnd}
                  />
                ) : (
                  <td key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
