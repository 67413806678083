import React from 'react';
import { MenuItemProps } from '#/packages/ui';

import { MenuItemInfo } from '#/packages/ui/src/components/Menu/MenuItemInfo';

export const MenuItemActions: React.FC<MenuItemProps> = (props) => {
  const { item } = props;

  return (
    <div className={'flex gap-1 items-center overflow-x-hidden absolute right-0 z-10 h-8 px-1'}>
      <MenuItemInfo item={item} />
      {item.actions}
    </div>
  );
};
