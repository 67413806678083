'use client';
import React, { RefObject, useCallback, useRef } from 'react';

export function useScrollSync(refs: RefObject<HTMLElement | null>[]) {
  return useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      refs
        .filter((otherRef) => otherRef.current !== e.currentTarget)
        .forEach((otherRef) => {
          if (otherRef.current) {
            otherRef.current.scrollLeft = (e.currentTarget as HTMLElement).scrollLeft;
          }
        });
    },
    [refs]
  );
}
