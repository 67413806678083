import { useCallback, useEffect, useState } from 'react';

export const useOpenState = (props: {
  open?: boolean;
  controlled?: boolean;
  onOpenChange: (isOpen: boolean) => void;
}) => {
  const { open = false, controlled = false, onOpenChange } = props;
  const [isOpen, setIsOpen] = useState(open || false);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!controlled) {
        setIsOpen(isOpen);
      }
      onOpenChange?.(isOpen);
    },
    [controlled, onOpenChange]
  );

  useEffect(() => {
    if (isOpen !== open) {
      setIsOpen(open);
    }
  }, [open]);

  return {
    isOpen,
    handleOpenChange
  };
};
