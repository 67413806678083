import React from 'react';
import { classNames } from '../../utils/classNames';
import { Menu, MenuProps } from '../Menu';

export type DropdownMenuProps = MenuProps;

export const DropdownMenu: React.FC<DropdownMenuProps> = React.memo((props) => {
  return (
    <Menu
      {...props}
      className={classNames(
        'max-h-[55vh] overflow-auto outline-0 scrollbar [scrollbar-width:none]',
        props.className
      )}
    />
  );
});
