'use client';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { MenuSection as BaseMenuSection, Popover, SubmenuTrigger } from 'react-aria-components';
import {
  MenuSectionHeader,
  MenuSectionHeaderProps
} from '#/packages/ui/src/components/Menu/MenuSectionHeader';
import { Menu } from './Menu';
import { MenuButtonItem, MenuItem } from './MenuItem';
import { UseMenuSearch } from './MenuSearch/useMenuSearch';
import { MenuSeparator, MenuSeparatorPlaceholder } from './MenuSeparator';
import { ShowMoreMenuItem } from './ShowMoreMenuItem';
import { IMenuItem, MenuItems, MenuKey, MenuSelectionMode } from './types';
import { classNames } from '../../utils/classNames';

export interface MenuSectionProps {
  size?: 'sm' | 'md' | 'lg';
  index: number;
  items: MenuItems;
  hidden?: boolean;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  header?: React.ReactNode;
  first?: boolean;
  last?: boolean;
  truncate?: number;
  nextWithSeparator?: number;
  collapsible?: boolean;
  multiple?: boolean;
  expanded?: boolean;
  setExpanded: (ind: number) => void;
  selectionMode?: MenuSelectionMode;
  withCheckMark?: boolean;
  MenuSectionHeaderComponent?: React.FC<MenuSectionHeaderProps>;
  className?: string;
  sortFn?: UseMenuSearch<IMenuItem | '-'>['sort'];
}

export const MenuSection: React.FC<MenuSectionProps> = (props) => {
  const {
    index,
    header,
    items,
    hiddenKeys = [],
    selectedKeys,
    selectionMode,
    withCheckMark = false,
    size,
    first,
    last,
    truncate,
    nextWithSeparator,
    className,
    scrollable = false,
    multiple = false,
    collapsible,
    expanded,
    setExpanded,
    MenuSectionHeaderComponent = MenuSectionHeader,
    sortFn,
    minHeight
  } = props;

  const isHidden = items.every(
    (item) =>
      item !== MenuSeparatorPlaceholder && Array.from(hiddenKeys).includes(item.id as MenuKey)
  );

  const [limit, setLimit] = useState(truncate || Infinity);

  const totalLength = items.length;

  const hidden = totalLength - limit;
  const limitable = collapsible && !hiddenKeys?.length && hidden > 1;
  const limited = limit > -1 && limitable && items.length > 8;

  const selectedItems = useMemo(
    () => selectedKeys?.map((key) => items.find((item) => item.id === key)).filter(Boolean),
    [selectedKeys, items]
  );

  const visibleItems = useMemo(() => {
    const visibleItems = [...items].sort(sortFn).slice(0, limited ? limit : Infinity);

    let c1 = visibleItems;

    const c2 = selectedItems.slice(0, limited ? limit : Infinity).filter((it) => !c1.includes(it));

    if (c2.length) {
      c1 = c1.slice(0, Math.max(0, Math.min(c1.length - c2.length, limit)));
    }

    return limited ? [...c1, ...c2].sort(sortFn) : visibleItems;
  }, [items, selectedItems, limited, limit, sortFn]);

  const selectedHiddenItems = useMemo(
    () =>
      selectedKeys
        ?.filter((key) => visibleItems.every((item) => item.id !== key))
        .map((key) => items.find((item) => item.id === key))
        .filter(Boolean),
    [selectedKeys, visibleItems, items]
  );

  useEffect(() => {
    if (collapsible && !expanded) {
      setLimit(truncate || Infinity);
    }
  }, [collapsible, expanded, truncate]);

  return (
    <BaseMenuSection
      className={classNames(
        'flex flex-col',
        isHidden ? 'hidden' : 'px-2',
        scrollable ? 'overflow-y-auto scrollbar' : '',
        scrollable && expanded ? '' : 'shrink-0',
        expanded ? 'pb-1' : '',
        className
      )}
      style={
        scrollable && collapsible
          ? {
              scrollbarGutter: 'stable',
              minHeight: !expanded ? undefined : minHeight
            }
          : {}
      }
      dependencies={[limit, hiddenKeys, items, limited]}
    >
      {collapsible && !hiddenKeys?.length && !first && expanded && !scrollable ? (
        <MenuSeparator />
      ) : null}
      {header ? (
        <MenuSectionHeaderComponent
          group={header}
          // itemsCount={items.filter((item) => item !== MenuSeparatorPlaceholder).length}
          expanded={expanded}
          selectedItems={selectedItems}
          onExpanded={collapsible ? () => setExpanded(index) : undefined}
        />
      ) : null}
      {visibleItems.map((item, i) =>
        item === MenuSeparatorPlaceholder ? (
          <MenuSeparator key={i} />
        ) : item.submenu ? (
          <SubmenuTrigger key={item.id}>
            <MenuButtonItem
              size={size}
              key={item.id}
              item={{
                ...item,
                label: (
                  <div className='flex justify-between w-full items-center'>
                    <span>{item.label}</span>
                    <ChevronRightIcon className='w-4 h-4' />
                  </div>
                )
              }}
              className={'mb-px'}
              hidden={
                (collapsible && !expanded) || Array.from(hiddenKeys).includes(item.id as MenuKey)
              }
            />
            <Popover
              className={classNames(
                'overflow-y-auto bg-white rounded shadow-lg ring-1 ring-black ring-opacity-5 max-w-[calc(100vw-2rem)]',
                'entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards'
              )}
            >
              <Menu items={item.submenu} />
            </Popover>
          </SubmenuTrigger>
        ) : item.onClick || item.to || item.href ? (
          <MenuButtonItem
            key={item.id}
            item={item}
            size={size}
            className={'mb-px'}
            hidden={
              (collapsible && !expanded) || Array.from(hiddenKeys).includes(item.id as MenuKey)
            }
          />
        ) : (
          <MenuItem
            key={item.id}
            item={item}
            size={size}
            className={'mb-px'}
            hidden={
              (collapsible && !expanded) || Array.from(hiddenKeys).includes(item.id as MenuKey)
            }
            selectionMode={selectionMode}
            withCheckMark={withCheckMark || multiple}
          />
        )
      )}
      {limitable && truncate > -1 && (!collapsible || expanded) ? (
        <ShowMoreMenuItem
          key={`${header}-more`}
          groupId={header}
          limit={limit}
          total={items.length}
          selectedItems={selectedHiddenItems}
          onClick={() => setLimit((prev) => (prev === truncate ? -1 : truncate))}
        />
      ) : null}
      {collapsible && !nextWithSeparator && !last && expanded && !scrollable ? (
        <MenuSeparator className={'mb-0'} />
      ) : null}
    </BaseMenuSection>
  );
};
