import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Header } from 'react-aria-components';
import { Tooltip, classNames } from '#/packages/ui';

export type MenuSectionHeaderProps = {
  group: React.ReactNode | string;
  headers?: Record<string, React.ReactNode>;
  actions?: React.ReactNode;
  actionsPre?: React.ReactNode;
  expanded?: boolean;
  onExpanded?: (expanded: boolean) => void;
  itemsCount?: number;
  className?: string;
};
export const MenuSectionHeader: React.FC<MenuSectionHeaderProps> = (props) => {
  const {
    group,
    headers,
    itemsCount,
    actions,
    actionsPre,
    expanded,
    onExpanded,
    className,
    selectedItems
  } = props;

  const finalHeader =
    headers && typeof group === 'string' ? (
      headers[group]
    ) : (
      <div className={'flex items-center gap-x-2'}>
        {group}
        {!expanded && itemsCount > 0 ? (
          <div className={'flex justify-center items-center p-2'}>
            <span className={'text-gray-400 text-[10px] font-normal'}>{itemsCount}</span>
          </div>
        ) : null}
        {!expanded && selectedItems?.length ? (
          <div
            data-tooltip-id={`${group}-tooltip`}
            className={'w-1 h-1 bg-gray-200 flex justify-center items-center p-2 rounded-full'}
          >
            <span className={'text-black text-[10px] font-normal'}>{selectedItems.length}</span>
          </div>
        ) : null}
        {!expanded && selectedItems?.length ? (
          <Tooltip
            id={`${group}-tooltip`}
            positionStrategy={'fixed'}
            content={`${selectedItems.length} selected: ${selectedItems.map((item) => item.label).join(', ')}`}
          />
        ) : null}
      </div>
    );

  return finalHeader ? (
    <Header
      className={classNames(
        'group font-medium py-2 px-2 pr-0 text-xs text-dark-900 tracking-wide sticky top-0 z-10 bg-gradient-to-t from-transparent via-white to-white via-[10%] transition-all',
        onExpanded || actions ? 'flex gap-2 justify-between' : '',
        onExpanded ? 'hover:text-black cursor-pointer' : '',
        expanded
          ? 'pb-1 text-black border-b border-px border-gray-100'
          : onExpanded
            ? 'pb-1 border-b border-px border-gray-100'
            : '',
        className,
        'leading-6'
      )}
      onClick={
        onExpanded
          ? () => {
              onExpanded(!expanded);
            }
          : undefined
      }
    >
      {finalHeader}
      <div className={'flex items-center gap-x-2'}>
        {actionsPre}
        {onExpanded ? (
          <ChevronDownIcon
            className={classNames(
              'transition-all ease-in text-gray-500 group-hover:text-gray-800 rounded w-6 h-6 p-1',
              expanded ? 'text-gray-700 -rotate-180' : ''
            )}
          />
        ) : null}
        {actions}
      </div>
    </Header>
  ) : null;
};
