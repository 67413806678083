import React, { forwardRef } from 'react';
import styles from './sidebar.module.css';
import { ResizeHandler } from '../ResizeHandler';

export interface SidebarResizeHandlerProps {
  isResizing: boolean;
  onPointerDown: (e: React.PointerEvent<HTMLDivElement>) => void;
}

export const SidebarResizeHandler = forwardRef<HTMLDivElement, SidebarResizeHandlerProps>(
  (props, ref) => {
    const { isResizing, ...rest } = props;

    return (
      <ResizeHandler
        ref={ref}
        className={styles.sidebar__resizer}
        isResizing={isResizing}
        orientation={'horizontal'}
        {...rest}
      />
    );
  }
);
