import React, { memo } from 'react';
import { ModalOverlay, ModalOverlayProps } from '../Modal';

export type SidebarOverlayProps = ModalOverlayProps;

export const SidebarOverlay: React.FC<SidebarOverlayProps> = memo((props) => {
  const { ...rest } = props;

  return <ModalOverlay {...rest} />;
});
