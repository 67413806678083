import React from 'react';
import { Button, ButtonProps, classNames } from '#/packages/ui';
import { ButtonAction } from '#/packages/ui/src/components/Dropdown/useActions';
type ActionButtonProps = ButtonAction &
  ButtonProps & {
    isOpen?: boolean;
    showOnHover?: boolean;
    withChevron?: boolean;
  };

export const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const {
    icon,
    iconProps = {},
    isOpen,
    withChevron,
    className,
    active,
    showOnHover,
    ...rest
  } = props;

  return (
    <Button
      size={'sm'}
      color={'neutral'}
      weight={'ghost'}
      {...rest}
      tip={
        typeof rest.tip === 'string' ? { content: rest.tip, positionStrategy: 'fixed' } : rest.tip
      }
      isActive={active}
      noBorder
      className={classNames(
        'p-0.5 transition-all',
        isOpen
          ? 'text-gray-400 hover:text-white hover:bg-dark-950'
          : 'text-gray-500 group-hover:text-gray-600 group-hover:hover:text-secondary-950 hover:bg-neutral-400 hover:text-secondary-950',
        withChevron && isOpen && 'opacity-0 pointer-events-none',
        withChevron && !isOpen && 'opacity-100',
        showOnHover &&
          !active &&
          'sm:opacity-0 sm:p-0 sm:w-0 sm:group-hover:opacity-100 sm:group-hover:p-0.5 sm:group-hover:w-5',
        active && 'text-black bg-black/10',
        className
      )}
      icon={icon}
      iconProps={{ ...iconProps, className: classNames('w-4 h-4', iconProps.className) }}
    />
  );
};

export const Actions = ({
  actions,
  isOpen,
  withChevron
}: {
  actions: ActionButtonProps[];
  isOpen: boolean;
  withChevron: boolean;
}) => {
  return (
    <div className={'flex items-center absolute right-1 top-[4px] -translate-x-px gap-0.5'}>
      {actions?.map((action) => (
        <ActionButton key={action.id} {...action} isOpen={isOpen} withChevron={withChevron} />
      ))}
    </div>
  );
};
