'use client';

import NextImage, { ImageProps as NextImageProps } from 'next/image';
import React, { useState } from 'react';
import { checkImgSrcLoaded } from '../../utils';
import { classNames } from '../../utils/classNames';
import { Spinner } from '../Spinner';

const ImageLoader = () => (
  <Spinner className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'} />
);

export interface ImageProps extends Omit<NextImageProps, 'loading'> {
  lowSrc?: string;
  loading?: boolean;
  Loader?: React.FC<any> | null;
  Fallback?: React.FC<any>;
  onClick?: (e: React.SyntheticEvent) => void;
  onLoad?: (e: React.SyntheticEvent) => void;
  onError?: (e: React.SyntheticEvent) => void;
}

export const Image: React.FC<ImageProps> = (props) => {
  const {
    className,
    onClick,
    Fallback,
    Loader = ImageLoader,
    loading: initialLoading,
    lowSrc,
    onLoad,
    onError,
    ...rest
  } = props;

  const [failed, setFailed] = useState(!props.src && !initialLoading);
  const [lowFailed, setLowFailed] = useState(!lowSrc && !initialLoading);
  const [loading, setLoading] = useState(() => !checkImgSrcLoaded(props.src) || initialLoading);
  const [lowLoading, setLowLoading] = useState(!checkImgSrcLoaded(lowSrc));

  return (
    <div
      className={classNames(
        'relative inline-block flex items-center justify-center overflow-hidden',
        onClick ? 'cursor-pointer' : '',
        className
      )}
      onClick={onClick}
    >
      {failed && lowFailed && Fallback ? (
        <Fallback />
      ) : (
        <>
          {loading && Loader ? <Loader /> : null}
          {(loading || failed) && lowSrc ? (
            <div className={'absolute inset-0'}>
              <NextImage
                {...rest}
                className={classNames(
                  'max-w-none w-full object-cover transition-opacity duration-750',
                  lowLoading ? 'opacity-0' : 'opacity-1'
                )}
                onLoad={(e) => {
                  setLowLoading(false);
                  onLoad?.(e);
                }}
                onError={() => {
                  setLowFailed(true);
                }}
                src={lowSrc}
                alt={`${rest.alt} low quality version`}
                unoptimized={
                  rest.unoptimized ||
                  lowSrc?.includes(`storage.superads.ai`) ||
                  lowSrc?.includes(`files.superads.ai`) ||
                  lowSrc?.includes(`img.youtube.com`)
                }
              />
              <div className={'absolute inset-0 backdrop-blur-md'} />
            </div>
          ) : null}
          {rest.src ? (
            <NextImage
              className={classNames(
                'max-w-none w-full object-cover transition-opacity ',
                lowLoading ? 'duration-750' : 'duration-0',
                loading || failed ? 'opacity-0' : 'opacity-1'
              )}
              onLoad={(e) => {
                setLoading(false);
                onLoad?.(e);
              }}
              onError={(e) => {
                setFailed(true);
                onError?.(e);
              }}
              {...rest}
              unoptimized={
                typeof rest.src === 'string' &&
                (rest.src?.includes(`storage.superads.ai`) ||
                  rest.src?.includes(`files.superads.ai`) ||
                  rest.src?.includes(`img.youtube.com`))
              }
            />
          ) : null}
        </>
      )}
    </div>
  );
};
