'use client';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { DOMAttributes } from '@react-types/shared';
import React from 'react';
import { AriaDatePickerProps, DateValue } from 'react-aria';
import { RangeCalendarState } from 'react-stately';
import { TIME_RANGE_TYPE } from '#/src/types/TimeRangeType';
import { CalendarButton } from './CalendarButton';
import { CalendarGrid } from './CalendarGrid';

interface RangeCalendarProps {
  rangeCalendarProps: DOMAttributes;
  prevButtonProps: AriaDatePickerProps<DateValue>;
  nextButtonProps: AriaDatePickerProps<DateValue>;
  title: string;
  rangeCalendarRef: React.MutableRefObject<null>;
  rangeCalendarState: RangeCalendarState;
  timeRangeType?: TIME_RANGE_TYPE;
  disableRange?: boolean;
  compareStart?: AriaDatePickerProps<DateValue>;
  compareEnd?: AriaDatePickerProps<DateValue>;
}

export const RangeCalendar: React.FC<RangeCalendarProps> = ({
  rangeCalendarProps,
  prevButtonProps,
  nextButtonProps,
  title,
  rangeCalendarRef,
  rangeCalendarState,
  disableRange,
  compareStart,
  compareEnd
}) => (
  <div {...rangeCalendarProps} ref={rangeCalendarRef} className='inline-block pb-4'>
    <div className='flex items-center'>
      <span className='flex-1 text-gray-750 text-xs font-medium uppercase tracking-wider'>
        {title}
      </span>
      <CalendarButton {...prevButtonProps}>
        <ChevronLeftIcon className='h-6 w-6 text-gray-500' />
      </CalendarButton>
      <CalendarButton {...nextButtonProps}>
        <ChevronRightIcon className='h-6 w-6 text-gray-500' />
      </CalendarButton>
    </div>
    <CalendarGrid
      state={rangeCalendarState}
      disableRange={disableRange}
      compareStart={compareStart}
      compareEnd={compareEnd}
    />
  </div>
);
