'use client';

import React, { ReactNode, useRef } from 'react';
import { mergeProps, useButton, useFocusRing } from 'react-aria';
import { classNames } from '#/packages/ui';

type CalendarButtonProps = {
  isDisabled?: boolean;
  children: ReactNode;
};

export const CalendarButton: React.FC<CalendarButtonProps> = (props) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={classNames(
        `p-1.5 rounded-full outline-none`,
        props.isDisabled ? 'text-gray-400' : 'hover:bg-secondary-300 active:bg-secondary-400',
        isFocusVisible && 'ring-2 ring-offset-2 ring-primary-600'
      )}
    >
      {props.children}
    </button>
  );
};
