import React, { LegacyRef, forwardRef, memo } from 'react';
import { Tooltip as BaseTooltip, ITooltip, TooltipRefProps } from 'react-tooltip';
import { classNames } from '../../utils/classNames';

export type TooltipProps = ITooltip & {
  title?: React.ReactNode;
};

const closeEvents = {
  mouseout: true,
  blur: true,
  mouseleave: true,
  click: true,
  dblclick: false,
  mouseup: false
};

export const Tooltip: React.FC<TooltipProps> = memo(
  forwardRef((props, ref) => {
    const { variant = 'dark', className, children, title, content = children, ...rest } = props;

    return title || content ? (
      <BaseTooltip
        ref={ref as LegacyRef<TooltipRefProps>}
        delayShow={300}
        opacity={0.96}
        noArrow
        closeEvents={closeEvents}
        className={classNames(
          'rounded !p-2 !z-[100] !text-[11px] !font-normal max-w-[80vw] sm:max-w-lg',
          {
            '!bg-white !shadow-2xl !text-black': variant === 'light',
            '!bg-secondary-950 !shadow-2xl !text-white': variant === 'dark'
          },
          className
        )}
        {...rest}
      >
        <div className={'max-w-full font-medium flex flex-col items-start justify-start gap-y-1'}>
          {typeof title === 'string' ? <TooltipTitle>{title}</TooltipTitle> : title}
          {typeof content === 'string' ? <TooltipBody>{content}</TooltipBody> : <>{content}</>}
        </div>
      </BaseTooltip>
    ) : null;
  })
);

export const TooltipTitle: React.FC<React.ComponentProps<'h3'>> = memo((props) => (
  <h3
    className={classNames(
      'w-full text-xs text-left inline whitespace-pre-wrap font-semibold text-white',
      props.className
    )}
  >
    {props.children}
  </h3>
));
export const TooltipBody: React.FC<React.ComponentProps<'p'>> = memo((props) => (
  <p
    className={classNames(
      'text-[11px] max-w-full whitespace-break-spaces text-start font-medium',
      props.className
    )}
  >
    {props.children}
  </p>
));
