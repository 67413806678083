/* eslint-disable import/no-duplicates */
'use client';

import {
  differenceInMinutes,
  differenceInSeconds,
  formatDistance,
  formatDistanceStrict,
  intlFormatDistance
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { utcToZonedTime } from 'date-fns-tz';
import React, { ReactNode, memo, useEffect, useId, useMemo, useState } from 'react';
import { useCurrentTime, useTimeStamp } from '../../hooks';
import { roundFormatDistance } from '../../utils/date';
import { Tooltip } from '../Tooltip';

type formatTypes = 'strict' | 'helpers' | 'human' | 'round';

export type RelativeTimeStampProps = {
  time: number | Date;
  format?: formatTypes;
  prefix?: string | ReactNode;
  suffix?: string | ReactNode;
  nowSuffix?: string | ReactNode;
  tooltip?: boolean | string;
  live?: boolean;
};

const getRelativeLabelByFormat = (
  format: formatTypes,
  zonedTime: number | Date,
  now: number | Date
) => {
  switch (format) {
    case 'strict': {
      return formatDistanceStrict(zonedTime, now, { locale: enUS });
    }
    case 'helpers': {
      return formatDistance(zonedTime, now, { locale: enUS });
    }
    case 'round': {
      return roundFormatDistance(zonedTime, now);
    }
    default: {
      return intlFormatDistance(zonedTime, now, { locale: enUS.code });
    }
  }
};

export const useRelativeTimeTooltipTimeStamp = (props) =>
  useTimeStamp({
    time: props.time,
    timeZone: props.timezone,
    dateFormat: 'do MMMM yyyy',
    timeFormat: 'HH:mm a',
    zoneFormat: null,
    divider: ', '
  });

export const RelativeTimeStamp: React.FC<RelativeTimeStampProps> = memo((props) => {
  const {
    live = false,
    time,
    prefix,
    suffix,
    nowSuffix = '',
    format = 'human',
    timezone,
    tooltip,
    Component = 'span',
    ...rest
  } = props;

  const id = useId();
  const [refreshCycle, setRefreshCycle] = useState(1000);
  const currentTime = useCurrentTime({ live, refreshCycle });
  const relativeTime = useCurrentTime({ time });
  const zonedTime = timezone ? utcToZonedTime(relativeTime, timezone) : relativeTime;

  useEffect(() => {
    if (differenceInSeconds(currentTime, zonedTime) < 60) {
      setRefreshCycle(1000);
    } else if (differenceInMinutes(currentTime, zonedTime) < 60) {
      setRefreshCycle(1000 * 60);
    } else {
      setRefreshCycle(1000 * 60 * 60);
    }
  }, [currentTime, zonedTime]);

  const timestampLabel = getRelativeLabelByFormat(format, zonedTime, currentTime);
  const label = useMemo(
    () =>
      [prefix, timestampLabel, timestampLabel.endsWith('now') ? nowSuffix : suffix]
        .filter(Boolean)
        .reduce((prev, curr) => [...prev, ' ', curr], []),
    [prefix, nowSuffix, suffix, timestampLabel]
  );
  const { timestamp } = useTimeStamp({
    time,
    timeZone: timezone,
    dateFormat: 'do MMMM yyyy',
    timeFormat: 'HH:mm a',
    zoneFormat: null,
    divider: ', '
  });

  const finalLabel = useMemo(
    () => (label?.every((chunk) => typeof chunk === 'string') ? label.join('') : label),
    [label]
  );

  return (
    <RelativeTimeStampLabel
      id={id}
      timestamp={timestamp}
      tooltip={tooltip}
      Component={Component}
      {...rest}
    >
      {finalLabel}
    </RelativeTimeStampLabel>
  );
});

const RelativeTimeStampLabel: React.FC<{
  id: string;
  timestamp: string;
  tooltip?: any;
  Component?: React.FC;
  children?: any;
}> = memo((props) => {
  const { id, timestamp, tooltip, Component = 'span', ...rest } = props;

  return (
    <>
      <Component data-tooltip-id={tooltip && id} {...rest}>
        {rest.children}
        {tooltip ? (
          <Tooltip id={id} content={typeof tooltip === 'string' ? tooltip : timestamp} />
        ) : null}
      </Component>
    </>
  );
});

export default RelativeTimeStamp;
