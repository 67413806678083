import React from 'react';
import { classNames } from '../../utils/classNames';
import { Button, ButtonProps } from '../Button';

export interface ButtonGroupProps {
  size?: ButtonProps['size'];
  color?: ButtonProps['color'];
  options: {
    id: string;
    Icon?: ButtonProps['icon'];
    title?: ButtonProps['tip'];
    label?: React.ReactNode;
    testId?: string;
  }[];
  value: string;
  onChange: (value: string) => void;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  const { options, value, size = 'sm', onChange } = props;

  return (
    <div className='isolate inline-flex rounded-md'>
      {options.map((option, i) => {
        const { id, Icon, title, label, testId } = option;

        return (
          <Button
            key={id}
            tip={title}
            size={size}
            color={'white'}
            weight={'outline'}
            isActive={id === value}
            data-testid={testId}
            className={classNames(
              'relative -ml-px inline-flex rounded-none focus-visible:ring-offset-0',
              i === 0 ? 'rounded-l border-r-0' : '',
              i === options.length - 1 ? 'rounded-r border-l-0' : ''
            )}
            onClick={() => onChange(id)}
            icon={Icon}
          >
            {label}
          </Button>
        );
      })}
    </div>
  );
};
