import React, { ComponentType, ElementType, ForwardedRef, SyntheticEvent, useMemo } from 'react';
import { Button as AriaButton, ButtonProps as AriaButtonProps } from 'react-aria-components';
import { ForwardRef } from 'react-is';

import {
  getButtonBaseClasses,
  getButtonLayoutClasses,
  getButtonSizeClasses,
  getButtonViewClasses
} from './classes';
import {
  ButtonColor,
  ButtonJustify,
  ButtonSide,
  ButtonSize,
  ButtonWeight,
  ControlTooltipProps
} from './types';
import { classNames } from '../../utils/classNames';
import { getIcon } from '../Icon';
import { Tooltip, useToolTip } from '../Tooltip';

export interface ButtonProps extends AriaButtonProps {
  /**
   * Button size
   * @default 'sm'
   * @type ButtonSize
   * @optional
   */
  size?: ButtonSize;
  /**
   * Button color
   * @type ButtonColor
   * @optional
   */
  color?: ButtonColor;
  /**
   * Button weight
   * @default 'solid'
   * @type ButtonWeight
   */
  weight?: ButtonWeight;
  /**
   * Button type
   * @default 'button'
   * @type 'button' | 'submit' | 'reset'
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Button is static
   * @type boolean
   * @optional
   */
  isStatic?: boolean;
  /**
   * Button is active
   * @type boolean
   * @optional
   */
  isActive?: boolean;
  /**
   * Button is loading
   * @type boolean
   * @optional
   */
  isLoading?: boolean;
  /**
   * Button is disabled
   * @type boolean
   * @optional
   */
  isDisabled?: boolean;
  gap?: number;
  href?: string;
  target?: string;
  side?: ButtonSide;
  plain?: boolean;
  noBorder?: boolean;
  wFull?: boolean;
  clickArea?: boolean;
  className?: string;
  flex?: boolean;
  justify?: ButtonJustify;
  component?: ComponentType<any> | ElementType<any>;
  tip?: React.ReactNode | Partial<ControlTooltipProps>;
  tipProps?: Partial<ControlTooltipProps>;
  loader?: React.FC<any>;
  icon?: React.ReactNode | React.FC<any> | typeof ForwardRef;
  iconProps?: { className?: string };
  onClick?: (e: SyntheticEvent) => void;
  disableExtraIconPadding?: boolean;
  'data-testid'?: string;
  'data-state'?: string;
}

export const Button = React.forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      type,
      plain,
      size = 'sm',
      color,
      weight = 'solid',
      side,
      flex: initialFlex,
      wFull,
      justify,
      noBorder,
      clickArea,
      isLoading,
      isDisabled,
      isStatic,
      isActive,
      href,
      tip,
      tipProps,
      loader,
      icon: initialIcon,
      disableExtraIconPadding,
      iconProps,
      children,
      component,
      className,
      onClick,
      ...rest
    } = props;

    const Component = component || (href ? 'a' : AriaButton);

    const tooltip = useToolTip(tip, tipProps);

    const icon = getIcon({
      icon: initialIcon,
      iconProps: useMemo(
        () => ({
          ...iconProps,
          className: classNames(
            {
              'text-white': isActive && color === 'white'
            },
            iconProps?.className
          )
        }),
        [iconProps]
      ),
      loader,
      isLoading
    });
    const withIcon = Boolean(icon);

    const iconOnly = Boolean(icon && !children);

    const flex = Boolean(
      initialFlex || withIcon || href || (Array.isArray(children) && children.length > 1)
    );

    return (
      <>
        <Component
          ref={ref}
          type={type}
          href={href}
          data-tooltip-id={tooltip?.id}
          aria-selected={isActive || false}
          className={classNames(
            'group/button',
            getButtonBaseClasses({ isDisabled, isStatic, clickArea }),
            getButtonLayoutClasses({ wFull, flex, justify }),
            getButtonSizeClasses({
              color,
              weight,
              size,
              side,
              plain,
              withIcon,
              iconOnly,
              noBorder,
              disableExtraIconPadding
            }),
            getButtonViewClasses({ color, weight, plain, isActive, isDisabled, noBorder }),
            className
          )}
          {...{ [Component === AriaButton ? 'isDisabled' : 'disabled']: isDisabled || isStatic }}
          // onClick is deprecated. Use onPress instead
          {...{ [Component === AriaButton ? 'onPress' : 'onClick']: onClick }}
          {...rest}
        >
          {icon}
          {children}
        </Component>

        {tooltip ? (
          <Tooltip key={isActive ? 'checked' : 'unchecked'} {...tooltip}>
            {tooltip.content}
          </Tooltip>
        ) : null}
      </>
    );
  }
);

export default Button;
