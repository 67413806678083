import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import React, { useMemo } from 'react';
import { classNames } from '../../utils/classNames';
import { Button, ButtonProps } from '../Button';

export type DropdownButtonProps = ButtonProps & {
  className?: string;
  isDisabled?: boolean;
  isOpen?: boolean;
  children: React.ReactNode;
  truncate?: boolean;
  withChevron?: boolean;
  contentClassName?: string;
  toggleIconProps?: ButtonProps['iconProps'];
  ToggleIcon?: React.FC<any>;
  onClick?: () => void;
  onOpen?: (isOpen: boolean) => void;
  isEdit?: boolean;
  editTip?: string;
  editTestId?: string;
  onEdit?: () => void;
  resetTip?: string;
  resetTestId?: string;
  onReset?: () => void;
  'data-tooltip-id'?: string;
  'data-tooltip-content'?: string;
  'data-userflow-id'?: string;
  'data-test-id'?: string;
};

export const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (props, ref) => {
    const {
      size = 'md',
      color,
      withChevron,
      isOpen,
      isStatic,
      isDisabled,
      children,
      ToggleIcon,
      truncate = true,
      iconProps,
      toggleIconProps,
      contentClassName,
      isEdit,
      editTip = 'Edit grouping',
      resetTip = 'Reset grouping',
      resetTestId = 'reset-button', //remove-filter-button
      editTestId = 'edit-button', //remove-filter-button
      onEdit,
      onReset,
      ...rest
    } = props;

    const ChevronIconComponent = useMemo(
      () =>
        ToggleIcon ||
        (withChevron
          ? (props) => (
              <ChevronDownIcon
                className={classNames(props.className, isOpen ? 'rotate-180' : null)}
              />
            )
          : null),
      [ToggleIcon, withChevron, isOpen]
    );

    const withToggleIcon = !isDisabled && !isStatic && ChevronIconComponent;

    const finalIconProps = useMemo(
      () => ({
        ...iconProps,
        className: classNames(
          iconProps?.className,
          'transition-all',
          {
            'text-gray-500 group-hover/dropdown:text-gray-600 group-hover/button:text-gray-600 group-focus-within:text-primary-700':
              color === 'white'
          },
          {
            'text-gray-400': isOpen && color === 'white'
          }
        )
      }),
      [iconProps, color, isOpen]
    );

    return (
      <Button
        ref={ref}
        size={size}
        color={color}
        isActive={Boolean(isOpen)}
        isStatic={Boolean(isStatic)}
        isDisabled={Boolean(isDisabled)}
        iconProps={finalIconProps}
        {...rest}
        className={classNames(rest.className, 'relative z-0')}
      >
        {children || withToggleIcon ? (
          <div
            className={classNames(
              'flex flex-1 items-center justify-between max-w-full',
              truncate ? 'truncate' : '',
              contentClassName
            )}
          >
            {children}
            {withToggleIcon && ChevronIconComponent && (
              <ChevronIconComponent
                className={classNames(
                  'shrink-0 transition ',
                  isOpen
                    ? { 'text-gray-100': color !== 'neutral' }
                    : 'text-gray-500 group-hover/dropdown:text-secondary-950',
                  'h-4 w-4 ml-2.5',
                  toggleIconProps?.className
                )}
                aria-hidden='true'
              />
            )}
          </div>
        ) : null}
      </Button>
    );
  }
);
