import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { MenuItemProps, Tooltip } from '#/packages/ui';

export const MenuItemInfo: React.FC<MenuItemProps> = (props) => {
  const { item } = props;

  return item.tooltip ? (
    <>
      <InformationCircleIcon
        data-tooltip-id={`menu-item-tooltip-${item.id}`}
        className={
          'w-4 h-4 group-hover/menu-item:opacity-100 opacity-0 text-dark-600 hover:text-dark-950'
        }
      />
      <Tooltip id={`menu-item-tooltip-${item.id}`} place={'right'} positionStrategy={'fixed'}>
        {item.tooltip}
      </Tooltip>
    </>
  ) : null;
};
