import React, { memo } from 'react';
import styles from './resizer.module.css';
import { classNames } from '../../utils';

export const ResizeHandler = memo(
  React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
      isResizing: boolean;
      orientation: 'horizontal' | 'vertical';
    }
  >((props, ref) => {
    const { isResizing, orientation = 'horizontal', className, ...rest } = props;

    return (
      <div
        ref={ref}
        className={classNames(
          styles.resizer,
          orientation === 'horizontal'
            ? styles.resizer_horizontal
            : orientation === 'vertical'
              ? styles.resizer_vertical
              : '',
          isResizing && styles.resizer_resizing,
          className
        )}
        {...rest}
      />
    );
  })
);
