'use client';

import {
  CalendarDate,
  getDayOfWeek,
  getLocalTimeZone,
  isSameDay,
  isToday,
  today
} from '@internationalized/date';
import React, { useRef } from 'react';
import {
  AriaDatePickerProps,
  DateValue,
  mergeProps,
  useCalendarCell,
  useFocusRing,
  useLocale
} from 'react-aria';
import { RangeCalendarState } from 'react-stately';
import { useWorkspace } from '#/src/context/session/hooks/useWorkspace';
import { classNames } from '../../utils';
import { Tooltip, useToolTip } from '../Tooltip';

export const CalendarCell: React.FC<{
  state: RangeCalendarState;
  date: CalendarDate;
  disableRange?: boolean;
  compareStart?: AriaDatePickerProps<DateValue>;
  compareEnd?: AriaDatePickerProps<DateValue>;
}> = ({ state, date, disableRange, compareStart, compareEnd }) => {
  const [workspace] = useWorkspace();
  const ref = useRef(null);
  const { cellProps, buttonProps, isSelected, isDisabled, formattedDate, isInvalid } =
    useCalendarCell({ date }, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { locale } = useLocale();
  const dayOfWeek = getDayOfWeek(date, locale);

  const isTodayHighlighted = workspace && isToday(date, workspace?.timezone);
  const isSelectionStart = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.start)
    : isSelected;
  const isSelectionEnd = state.highlightedRange
    ? isSameDay(date, state.highlightedRange.end)
    : isSelected;
  const isOtherMonthButSelected =
    !isSelected &&
    state.highlightedRange &&
    date.compare(state.highlightedRange.start) >= 0 &&
    date.compare(state.highlightedRange.end) <= 0;

  const comparePeriodSelected =
    compareStart?.value &&
    compareEnd?.value &&
    date.compare(compareStart.value) >= 0 &&
    date.compare(compareEnd.value) <= 0;
  const comparePeriodStart = compareStart?.value && date.compare(compareStart.value) === 0;
  const comparePeriodEnd = compareEnd?.value && date.compare(compareEnd.value) === 0;

  const isIntersectionWithComparePeriod =
    (isSelected || isOtherMonthButSelected) && comparePeriodSelected;

  const isRoundedLeft =
    (isSelected || isOtherMonthButSelected || comparePeriodSelected) &&
    (isSelectionStart || dayOfWeek === 0 || comparePeriodStart) &&
    !(
      isIntersectionWithComparePeriod &&
      (comparePeriodEnd || isSelectionEnd || comparePeriodStart || isSelectionStart) &&
      dayOfWeek !== 0
    );
  const isRoundedRight =
    (isSelected || isOtherMonthButSelected || comparePeriodSelected) &&
    (isSelectionEnd || dayOfWeek === 6 || comparePeriodEnd) &&
    !(
      isIntersectionWithComparePeriod &&
      (comparePeriodEnd || isSelectionEnd || comparePeriodStart || isSelectionStart) &&
      dayOfWeek !== 6
    );

  const tooltip = useToolTip(isIntersectionWithComparePeriod ? 'Overlap dates' : undefined, {
    positionStrategy: 'fixed',
    place: 'top'
  });

  return (
    <td
      {...cellProps}
      data-tooltip-id={tooltip?.id}
      className={`py-0.5 relative ${isFocusVisible ? 'z-10' : 'z-0'}`}
    >
      <div
        {...mergeProps(
          focusProps,
          disableRange
            ? {
                onClick: () => {
                  state.setValue({ start: date, end: today(getLocalTimeZone()) });
                  state.setFocusedDate(date);
                }
              }
            : buttonProps
        )}
        ref={ref}
        className={classNames(
          `w-9 h-9 text-sm outline-none group`,
          isRoundedLeft && 'rounded-l-full',
          isRoundedRight && 'rounded-r-full',
          isDisabled && 'disabled',
          isSelected || isOtherMonthButSelected
            ? isInvalid
              ? 'bg-destructive-600'
              : 'bg-primary-100'
            : '',
          comparePeriodSelected && 'bg-secondary-200',
          isIntersectionWithComparePeriod && 'bg-secondary-400',
          isIntersectionWithComparePeriod &&
            (comparePeriodStart || comparePeriodEnd) &&
            'bg-gradient-to-r from-secondary-400 to-primary-100 from-50% to-50%',
          isIntersectionWithComparePeriod &&
            (isSelectionStart || isSelectionEnd) &&
            'bg-gradient-to-r from-secondary-200 to-secondary-400 from-50% to-50%'
        )}
      >
        <div
          className={classNames(
            'w-full h-full rounded-full flex items-center justify-center cursor-default transition-all',
            isDisabled &&
              !isInvalid &&
              (isIntersectionWithComparePeriod ? 'text-gray-400' : 'text-gray-300'),
            isFocusVisible && 'ring-2 group-focus:z-2 ring-primary-400 ring-offset-2',
            isSelectionStart || isSelectionEnd || comparePeriodStart || comparePeriodEnd
              ? isInvalid
                ? 'bg-red-600 text-white hover:bg-red-700'
                : 'bg-primary-800 text-white hover:bg-primary-700'
              : '',
            (comparePeriodStart || comparePeriodEnd) &&
              'bg-secondary-950 text-white hover:bg-secondary-800',
            isSelected &&
              !isDisabled &&
              !(isSelectionStart || isSelectionEnd || comparePeriodStart || comparePeriodEnd)
              ? isInvalid
                ? 'hover:bg-red-400'
                : isIntersectionWithComparePeriod
                  ? 'hover:bg-secondary-500'
                  : 'hover:bg-primary-100'
              : '',
            !isSelected &&
              !isDisabled &&
              !comparePeriodStart &&
              !comparePeriodEnd &&
              'hover:bg-primary-100',
            isTodayHighlighted && !isSelected && !isDisabled && 'border-neutral-100 border-2'
          )}
        >
          {formattedDate}
        </div>
      </div>
      {tooltip ? <Tooltip {...tooltip}>{tooltip.content}</Tooltip> : null}
    </td>
  );
};
