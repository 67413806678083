import React from 'react';
import { Separator, SeparatorProps } from 'react-aria-components';
import { classNames } from '../../utils/classNames';

export const MenuSeparatorPlaceholder = '-';

export type MenuSeparatorProps = SeparatorProps;

export const MenuSeparator: React.FC<MenuSeparatorProps> = (props) => (
  <Separator
    {...props}
    className={classNames('bg-gray-200 h-[1px] mx-2 my-1 shrink-0', props.className)}
  />
);
