import React, { memo } from 'react';
import {
  MenuItem as BaseMenuItem,
  MenuItemProps as BaseMenuItemProps
} from 'react-aria-components';
import { MenuItemActions } from '#/packages/ui/src/components/Menu/MenuItemActions';
import { IMenuItem, MenuSelectionMode } from './types';
import { classNames } from '../../utils/classNames';
import { Button, ButtonProps } from '../Button';
import { Checkbox } from '../Checkbox';

export interface MenuItemProps {
  size?: 'sm' | 'md' | 'lg';
  item: IMenuItem;
  hidden?: boolean;
  selectionMode?: MenuSelectionMode;
  withCheckMark?: boolean;
  className?: string;
}

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { item, hidden, selectionMode, size, className, withCheckMark } = props;
  const { id, disabled, icon, buttonProps, ...rest } = item;

  return (
    <MenuItemContainer
      item={item}
      hidden={hidden}
      selectionMode={selectionMode}
      className={className}
    >
      {({ isSelected, isDisabled }) => (
        <div className={'flex relative group/menu-item'} key={`check-${id}`}>
          <MenuItemButton
            {...rest}
            {...buttonProps}
            noBorder
            size={size}
            id={`check-${id}`}
            selected={isSelected}
            disabled={isDisabled || disabled}
            selectionMode={selectionMode}
            className={
              selectionMode === 'multiple' || selectionMode === 'group-multiple'
                ? classNames(
                    buttonProps?.className,
                    'group/checkbox',
                    item.tooltip && 'group-hover/menu-item:pr-6 transition-all'
                  )
                : buttonProps?.className
            }
            icon={
              withCheckMark || selectionMode === 'multiple' ? (
                <Checkbox
                  className={'fade-in'}
                  color={'primary'}
                  id={`check-${id}`}
                  isSelected={isSelected}
                  isDisabled={isDisabled || disabled}
                  size={'sm'}
                />
              ) : (
                icon
              )
            }
          >
            <MenuItemLabel item={item} />
          </MenuItemButton>
          <MenuItemActions item={item} />
        </div>
      )}
    </MenuItemContainer>
  );
};

export const MenuButtonItem: React.FC<MenuItemProps> = (props) => {
  const { item, hidden, selectionMode, className, size } = props;
  const { id, current, disabled, buttonProps, ...rest } = item;

  return (
    <MenuItemContainer
      item={item}
      hidden={hidden}
      selectionMode={selectionMode}
      className={className}
    >
      {({ isSelected, isDisabled }) => (
        <div className={'flex relative group/menu-item'} key={`check-${id}`}>
          <MenuItemButton
            {...rest}
            {...buttonProps}
            size={size}
            id={`check-${id}`}
            selected={isSelected || current}
            disabled={isDisabled || disabled}
            iconProps={{
              className: 'text-gray-500',
              ...rest.iconProps
            }}
            className={classNames('font-medium', buttonProps?.className)}
          >
            <MenuItemLabel item={item} />
          </MenuItemButton>
          <MenuItemActions item={item} />
        </div>
      )}
    </MenuItemContainer>
  );
};

export const MenuItemButton: React.FC<
  ButtonProps & {
    selected?: boolean;
    disabled?: boolean;
    selectionMode?: MenuSelectionMode;
  }
> = memo((props) => {
  const { selected, disabled, selectionMode, className, size = 'sm', ...rest } = props;

  return (
    <Button
      color={'secondary'}
      weight={'ghost'}
      size={size}
      wFull
      flex
      // isActive={selected}
      isStatic={selected && selectionMode !== 'multiple'}
      isDisabled={disabled}
      className={classNames(
        'group relative flex items-center hover:bg-transparent hover:border-transparent outline-none text-sm px-2 transition-none font-normal',
        { 'py-4 px-4': size === 'lg' },
        { 'py-1.5': true },
        selected
          ? 'text-gray-900 bg-neutral-100 hover:bg-neutral-100'
          : disabled
            ? 'text-gray-400'
            : 'text-gray-800 hover:text-black hover:bg-neutral-50 group-hover/menu-item:text-black group-hover/menu-item:bg-neutral-50',
        !disabled &&
          (!selected || selectionMode === 'multiple' || selectionMode === 'group-multiple')
          ? 'hover:bg-neutral-100 group-hover/menu-item:bg-neutral-100'
          : '',
        disabled &&
          'text-gray-400 hover:text-gray-400 hover:bg-transparent group-hover/menu-item:bg-transparent group-hover/menu-item:text-gray-400',
        disabled && selected && 'group-hover/menu-item:bg-neutral-100',
        className
      )}
      {...rest}
    />
  );
});

export const MenuItemContainer: React.FC<MenuItemProps & BaseMenuItemProps> = (props) => {
  const { item, hidden, selectionMode, className, ...rest } = props;

  return (
    <BaseMenuItem
      id={item?.id}
      className={({ isDisabled, isSelected }) =>
        classNames(
          'relative outline-none',
          { hidden },
          (isSelected && selectionMode === 'single') || isDisabled
            ? 'cursor-default'
            : 'cursor-pointer',
          className
        )
      }
      {...rest}
    />
  );
};

export const MenuItemLabel: React.FC<MenuItemProps> = memo((props) => {
  const { item } = props;

  return (
    <div className={'flex flex-1 justify-start items-center overflow-x-hidden '}>
      {typeof item.label === 'string' ? (
        <span className={'truncate'}>{item.label}</span>
      ) : (
        item.label
      )}

      {item.extra}
    </div>
  );
});
