'use client';
import { useInView } from 'framer-motion';
import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import type { SelectOption } from './types';
import { useSelectItems } from './useSelectItems';
import { classNames } from '../../utils';
import { Button } from '../Button';
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuProps,
  DropdownMenuSearchField,
  DropdownProps
} from '../Dropdown';
import { DropdownMenuLabel } from '../Dropdown/DropdownMenuLabel';
import type { MenuKey } from '../Menu';
import { IMenuItem } from '../Menu';
import { useMenuSearch } from '../Menu/MenuSearch/useMenuSearch';
import { TextInputProps } from '../TextInput';

export interface SelectProps extends Omit<DropdownProps, 'children' | 'label'> {
  multiple?: boolean;
  withSearch?: boolean;
  withClose?: boolean;
  label?:
    | React.ReactNode
    | ((selectedProperties: IMenuItem[], state: { open: boolean }) => React.ReactNode);
  placeholder?: React.ReactNode;
  searchPlaceholder?: TextInputProps['placeholder'];
  value?: MenuKey | MenuKey[];
  options: SelectOption[];
  disabledKeys?: MenuKey[];
  optionsFooter?: React.ReactNode;
  matchOptions?: (option: SelectOption[], filter: string) => MenuKey[];
  onChange?: DropdownMenuProps['onAction'];
  onSelectionChange?: DropdownMenuProps['onSelectionChange'];
  onFilterChange?: (value: string) => void;
  MenuSectionHeaderComponent?: DropdownMenuProps['MenuSectionHeaderComponent'];
  dropdownClassName?: string;
  dropdownMenuStyle?: any;
  dropdownMenuClassName?: string;
  loadMore?: () => void;
}

export const Select: React.FC<
  SelectProps & Pick<DropdownMenuProps, 'emptyList'> & { hiddenKeys?: MenuKey[] }
> = forwardRef((props, ref) => {
  const {
    label,
    value,
    options,
    hiddenKeys,
    multiple,
    withSearch,
    withClose = false,
    placeholder,
    optionsFooter,
    disabledKeys: initialDisabledKeys,
    matchOptions,
    onChange,
    onSelectionChange,
    MenuSectionHeaderComponent,
    emptyList,
    searchPlaceholder,
    onOpenChange,
    onFilterChange: onFilterChangeProp,
    loadMore,
    truncate,
    dropdownClassName,
    dropdownMenuStyle,
    dropdownMenuClassName,
    ...rest
  } = props;

  const items = useSelectItems({ options });
  const [internalOpen, setInternalOpen] = useState(Boolean(props.open));
  const open = props.open !== undefined ? props.open : internalOpen;

  const popoverRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);

  const endOfList = useInView(endRef, { margin: '0px', once: false, root: popoverRef.current });

  const { filter, filteredKeys, onFilterChange, sort } = useMenuSearch<SelectOption>(options, {
    hiddenKeys,
    matchOptions
  });

  const handleOpenChange = (isOpen: boolean) => {
    setInternalOpen(isOpen);
    onOpenChange?.(isOpen);
    if (!isOpen) {
      onFilterChange('');
    }
  };

  const handleFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onFilterChange(e?.target?.value);
      onFilterChangeProp?.(e?.target?.value);
    },
    [onFilterChange, onFilterChangeProp]
  );

  const selectedKeys = useMemo<MenuKey[]>(
    () =>
      (Array.isArray(value) ? value : [value]).filter(
        (val) =>
          typeof val !== 'undefined' &&
          (loadMore || items?.some((it) => it.id && it.id === val) || !val || val === 'None')
      ) as MenuKey[],
    [value, loadMore]
  );
  const disabledKeys = useMemo<MenuKey[]>(
    () => initialDisabledKeys || options.filter((it) => it.disabled).map((it) => it.value),
    [initialDisabledKeys, options]
  );

  const selectedProperties = useMemo(() => {
    return selectedKeys
      .map((key) => {
        return (
          items?.find((it) => it.id && it.id === key) ||
          ((!key || key === 'None') && {
            label: key === null ? 'None' : key,
            value: key
          })
        );
      })
      .filter(Boolean);
  }, [items, selectedKeys]);

  useLayoutEffect(() => {
    if (loadMore && endRef.current && popoverRef.current && endOfList && open) {
      loadMore();
    }
  }, [endOfList, open, endRef.current, popoverRef.current, loadMore]);

  return (
    <Dropdown
      withChevron
      type={'menu'}
      size={'xs'}
      color={'neutral'}
      weight='outline'
      className={classNames('!max-h-[55vh] overflow-x-hidden', dropdownClassName)}
      label={
        typeof label === 'function' ? (
          label(selectedProperties, { open })
        ) : (
          <DropdownMenuLabel
            open={open}
            prefix={label}
            selectedProperties={selectedProperties}
            multiple={multiple}
            placeholder={placeholder}
          />
        )
      }
      ref={ref}
      popoverRef={popoverRef}
      {...rest}
      onOpenChange={handleOpenChange}
      open={open}
    >
      {withSearch ? (
        <DropdownMenuSearchField
          autoFocus={open}
          value={filter}
          placeholder={searchPlaceholder}
          onChange={handleFilterChange}
        />
      ) : null}

      <div className='max-h-[35vh] overflow-y-scroll'>
        <DropdownMenu
          truncate={truncate}
          style={dropdownMenuStyle}
          className={classNames('max-h-[auto]', dropdownMenuClassName)}
          emptyList={emptyList}
          selectionMode={multiple ? 'multiple' : 'single'}
          selectedKeys={selectedKeys}
          disabledKeys={disabledKeys}
          hiddenKeys={filteredKeys}
          withCheckMark={false}
          items={items}
          MenuSectionHeaderComponent={MenuSectionHeaderComponent}
          onAction={onChange}
          onSelectionChange={onSelectionChange}
          sortFn={sort}
        />
        {loadMore ? <div ref={endRef} className='h-[150px] w-full mt-[-150px]' /> : null}
        {optionsFooter}
      </div>

      {withClose ? (
        <div className='bg-white px-4 py-2'>
          <Button
            size='md'
            color='white'
            weight='outline'
            wFull
            onClick={() => handleOpenChange(false)}
          >
            Close
          </Button>
        </div>
      ) : null}
    </Dropdown>
  );
});
